<template>
  <default-layout :header-title="$t('agreements')" :hide-header="true">
    <p class="w-title">{{ $t('agreements') }}</p>
    <v-dialog />
    <div class="agreements-page">
      <AgreementNavigation/>
      <div class="cards-grid plr-16">
        <template>
          <template v-if="loadingLife || loadingSubs || loadingAgreements">
            <div class="image-card card py-4 px-3" v-for="item in 2" :key="item">
              <div class="">
                <h3 class="title skeleton skeleton-text w-75"></h3>
                <h3 class="title skeleton skeleton-text w-50"></h3>
                <h3 class="title skeleton skeleton-text w-25"></h3>
                <div class="gray-btn skeleton w-50"></div>
              </div>
            </div>
          </template>
          <div class="d-flex justify-content-center flex-column" v-else-if="agreements.length === 0 && lifeAgreements.length === 0 && subscriptions.length === 0">
            <img src="/img/empty.svg" alt="" class="centered-img">
            <h2 class="text-center fw-bold p-4" >
              {{$t('noAgreements')}}
            </h2>

            <router-link class="primary-btn" :to="{name: 'insurance.products', params: {id: 1, form_component: 'ogpo-form' }}">Оформить</router-link>
          </div>

          <template v-else>
            <agreement-card
                v-if="agreements.length > 0"
                v-for="(item, index) in agreements"
                :key="`active_agreements_${index}`"
                :agreement="item"
                :policies="policies"
                :isActive="true"
                type="archive"
                :is-expiration-color="true"
            />
            <agreement-card-subscription
                v-if="subscriptions.length > 0"
                v-for="(item, index) in subscriptions"
                :key="`active_subs_${index}`"
                :agreement="item"
                :isExpirationColor="true"
                :policies="policies"
                :isSubscription="true"
                type="archive"
                :isActive="true"
                :is-expiration-color="true"
            />
            <agreement-card-life
                v-if="lifeAgreements.length > 0"
                v-for="(item, index) in lifeAgreements"
                :key="`active_life_${index}`"
                :agreement="item"
                :isExpirationColor="true"
                :policies="policies"
                type="active"
                :isActive="true"
            />
          </template>
        </template>
      </div>
    </div>
  </default-layout>
</template>

<script>
import TheTabs from "@/components/TheTabs";
import AgreementCard from "@/components/AgreementCard";
import AgreementCardSubscription from "@/components/AgreementCardSubscription";
import AgreementCardLife from "@/components/AgreementCardLife";
import eventLogger from '../../services/eventLogger';
import AgreementNavigation from "../../components/AgreementNavigation";

export default {
  name: "Agreements",
  components: { AgreementNavigation, AgreementCard, TheTabs, AgreementCardSubscription, AgreementCardLife },
  data() {
    return {
      tabs: [
        {name: this.$i18n.t('effectiveAgreements'),},
        {name: this.$i18n.t('expiredAgreements'),},
      ],
      agreements: [],
      subscriptions: [],
      lifeAgreements: [],
      active: 0,
      loadingAgreements: true,
      loadingSubs: true,
      loadingLife: true,
      policies: null
    }
  },
  mounted() {
    eventLogger('view_contract_tab')

    this.getArchiveAgreements();
    this.getArchiveLifeAgreements();
    this.getArchiveAgreementsSubscription();

    this.getPolicies()
  },
  methods: {
    async getArchiveLifeAgreements() {
      this.loadingLife = true;
      try {
        const {data} = await window.axios.get('life/policies/active');
        this.lifeAgreements = data.policies || [];
      } catch (e) {
        throw e
      } finally {
        this.loadingLife = false;
      }
    },

    async getArchiveAgreementsSubscription() {
      this.loadingSubs = true;
      try {
        const {data} = await window.axios.get('subscription/active');
        this.subscriptions = data;
      } catch (e) {
        throw e
      } finally {
        this.loadingSubs = false;
      }
    },

    async getArchiveAgreements() {
      this.loadingAgreements = true;
      try {
        const {data} = await window.axios.get('policies/active');
        this.agreements = data;
      } catch (e) {
        throw e
      } finally {
        this.loadingAgreements = false;
      }
    },

    async getPolicies() {
      const {data} = await window.axios.get('insurance-types/all')

      this.policies = data;
    }
  },
}
</script>

<style lang="scss">
@import "../../sass/variables";
.plr-16{
  padding: 0 16px;
}
.centered-img{
  margin:auto;
}
</style>
